import React from "react"
import { Link, PageProps } from "gatsby"
import { WindowLocation } from "@reach/router"

import StandardLayout from "Components/core/StandardLayout"
import Accordion from "Components/Accordion"
import SEO from "Components/core/SEO"
interface PersonalContentProps {
  location: WindowLocation
}

export const PersonalContent: React.FC<PersonalContentProps> = ({
  location,
}) => {
  return (
    <StandardLayout location={location}>
      <SEO title="Personal" description="About Liam Myles" />
      <h1>Just Me</h1>
      <p>
        This page is a bit of an experiment. I have broken up 3 aspects of my
        life and broken them down into 3 different temporal flows.
      </p>
      <p>This page was last updated June 2021</p>
      <AboutMe />
    </StandardLayout>
  )
}

const PersonalPast: React.FC = () => (
  <>
    <h4>My path to the present</h4>
    <p>
      I was the firstborn of 3 boys, raised in Ireland, spending younger years
      in Donor, and moving to Drogheda when I was twelve.
    </p>
    <p>
      My mother is Bi-Polar and was a violent mother. My Dad was extradited and
      prosecuted for fraud when I was 7. I didn&apos;t see much of him for four
      years when he moved back home, only to leave Ireland a few years later.
    </p>
    <p>
      At age 11, I lived in American for six months, dragged back to my mother
      due to some laws and some lies.
    </p>
    <p>
      At age 15, my mother was committed for the first time. So I was allowed to
      move to England to live with my Dad.
    </p>
    <p>
      At age 17, I moved back to Ireland when my Dad lost his job during the
      2008 recession.
    </p>
    <p>17 to 19 were some of the toughest and left their mark.</p>
    <p>
      At age 19, I got accepted into a 1-year multimedia course and went to
      university right after graduating in 2015.
    </p>
    <p>
      After a year of being unable to find paid work, I spent most of my time
      playing World of Warcraft where I met my wife. Who is the second-best
      thing to ever happen to me
    </p>
    <p>
      Then in 2018, we had our son Darwin, the best thing that has ever happened
      to me.
    </p>
  </>
)
const PersonalPresent: React.FC = () => (
  <>
    <h4>What life is like now</h4>
    <p>
      Right now, I&apos;m doing everything I can to be a great Dad and Husband.
    </p>
    <p>
      Darwin is 3, and his personality is starting to develop. Watching him
      become him has honestly been the most rewarding part of my life so far.
    </p>
    <p>
      COVID-19 has made things more complex than they used to be. But our little
      family has without a doubt, grown stronger through the challenges.
    </p>
    <p>
      The extra time and energy from working remote have also lead me to
      understand myself. I always knew I saw and understood things differently
      from most people, but I never thought it could be diagnosable.
    </p>
    <p>
      It&apos;s both scary and unshackling to think, the way I think might have
      a name, and techniques to make it easier to live with. But it&apos;s a
      year-long wait for a specialist, so I won&apos;t know for a while yet.
    </p>
  </>
)
const PersonalFuture: React.FC = () => (
  <>
    <h4>What could the future look like?</h4>
    <p>I plan on watching my little monkey grow into a bigger monkey.</p>
    <p>I want to stay on the track I already am on.</p>
    <p>
      I have almost entirely healed from my childhood. And I get to spend every
      day of my life with the two people I love more deeply than I could ever
      express.
    </p>
  </>
)
const ProjectsPast: React.FC = () => (
  <>
    <h4>What I have tinkered with</h4>
    <p>
      During University I got to play with 3D, script writing, animation, flash,
      and web projects
    </p>
    <p>For my thesis I created an AR game in Unity which was a lot of fun.</p>
    <p>
      In the years since university I have created all sorts of little things, a
      few that stand out are, Hexout, Steam Filter and Playing With Hexagons.
    </p>
    <p>All of which you can see on my projects page.</p>
    <Link className="multiline" to="/projects">
      Check out my past Projects
    </Link>
    <p>
      I actually finished playing with Hexagons v3, haven&apos;t and ended up
      writing about it.
    </p>
    <Link
      className="multiline"
      to="/blog/development/playing-with-polygons---the-iceberg-story"
    >
      Check out my blog about the history of the project.
    </Link>
  </>
)
const ProjectsPresent: React.FC = () => (
  <>
    <h4>What tinkering I&apos;m doing now</h4>
    <p>
      The key thing that keeps my tinkering focus is this website, as I write
      this I&apos;m actually refining the site to launch it. But that
      doesn&apos;t mean the work is finished on it. I still have so many little
      tweaks I want to make.
    </p>
    <p>
      I&apos;m also working on a presentation about accessibility which should
      hopefully be finished sooner rather than later.
    </p>
  </>
)
const ProjectsFuture: React.FC = () => (
  <>
    <h4>What tinkering might happen?</h4>
    <p>
      Always wanted to make a cool web game, and I have a passion for unique UX
      that I would like to explore more.
    </p>
  </>
)
const WorkPast: React.FC = () => (
  <>
    <h4>What work I did</h4>
    <p>
      Got paid to build my first website all the way back in 2014, it was a
      super basic wordpress website that I built for my college.
    </p>
    <p>
      After searching for a year and moving country I got a full time job
      working for Cruise.co.uk, a PHP/Jquery Site. It was my first experience of
      real development, and it lets me say I worked professionally with SVN.
    </p>
    <p>
      After that I got to work for Connect in their JLR office. It was my first
      experience working to a strict scrum methodology. And I learned a great
      deal from the experience.
    </p>
    <p>Left Day out of the kids after COVID-19 hit the business hard</p>
    <Link className="multiline" to="/professional">
      Check out my CV for more
    </Link>
  </>
)
const WorkPresent: React.FC = () => (
  <>
    <h4>What work I&apos;m doing</h4>
    <p>
      Right now I&apos;m working for Purplebricks, only been here 3 months as I
      write this, but I&apos;m feeling really good about my future here.
    </p>
  </>
)
const WorkFuture: React.FC = () => (
  <>
    <h4>What I&apos;m aiming for</h4>
    <p>
      Not really sure where the future will lead me, but at this moment what I
      have in the back of my head is to become a Frontend Architect.
    </p>
    <p>
      One day I want to be helping create some of the most complex & beautiful
      website interfaces around.
    </p>
  </>
)

const AboutMe: React.FC = () => (
  <>
    <h2>Just Another Human</h2>
    <Accordion
      accordionComponents={[
        { title: "Past", component: PersonalPast },
        { title: "Present", component: PersonalPresent },
        { title: "Future", component: PersonalFuture },
      ]}
    />
    <h2>Projects</h2>
    <p>Read about the Tinkerer.</p>
    <Accordion
      accordionComponents={[
        { title: "Past", component: ProjectsPast },
        { title: "Present", component: ProjectsPresent },
        { title: "Future", component: ProjectsFuture },
      ]}
    />
    <h2>Work</h2>
    <p>Read about the Professional.</p>
    <Accordion
      accordionComponents={[
        { title: "Past", component: WorkPast },
        { title: "Present", component: WorkPresent },
        { title: "Future", component: WorkFuture },
      ]}
    />
  </>
)

const PersonalPage: React.FC<PageProps> = ({ location }) => (
  <PersonalContent location={location} />
)

export default PersonalPage
